.navbar .navbar-nav {
  display: inline-block;
  float: none;
  vertical-align: top;
}

.navbar .navbar-collapse {
  text-align: center;
}

.profile-img img {
    border-radius: 50%;
}

.author-promo {
    display: block;
}

