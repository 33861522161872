.footer_wrapper {
  position: absolute;
  width: 100%;
  margin-top: 2em;

	footer {
	  position: absolute;
	  bottom: 0;
	  width: 100%;
	  background: #52B7A6;
	  color: #fff;

		.footer_inner p {
		    margin-bottom: 0;
		    padding-top: 0.5em;
		    padding-bottom: 0.5em;
		    font-size: 1.2em;
		}
	    
	}

}



