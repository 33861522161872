#main_navbar {
	background: #52B7A6;
	border: 0;

	&.navbar-default .navbar-nav > li > a {
		color: #fff;
		font-size: 1.2em;
	}
	&.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
		color: #fff;
		background-color: #333;
	}

}

#sidr-main {

	&.sidr ul li {
	    background: #52B7A6;
	    border-bottom: 0;
	    box-shadow: none;
	    border-top: 1px solid #ccc;
	}

	&.sidr ul li:hover>a, &.sidr ul li:hover>span, &.sidr ul li.active>a, &.sidr ul li.active>span, &.sidr ul li.sidr-class-active>a, &.sidr ul li.sidr-class-active>span {
	    box-shadow: none;
	    color: #000;
	}
	&.sidr ul li a, &.sidr ul li span {
	    color: #fff;
	}
	&.sidr ul li:hover, &.sidr ul li.active, &.sidr ul li.sidr-class-active {
	    background: #333;
	}


}


