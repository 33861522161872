* {
	  transition: all 0.9s;
    -webkit-transition: all 0.9s;
    -moz-transition: all 0.9s;
    -ms-transition: all 0.9s;
}

body .btn-primary {
    color: #fff;
    background-color: #52B7A6;
    border-color: #52B7A6;
    border-radius: 0;
}