
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Homepage styling
@import "Homepage";

//Footer
@import "footer";

//Navbar
@import "navbar";

//Common
@import "common";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Animate.css
@import "animate.min";

//Sidr
@import "jquery.sidr.light.min";

//Ionicons
@import "node_modules/ionicons/dist/scss/ionicons.scss";

//Font awesome
@import "node_modules/font-awesome/scss/font-awesome.scss";



/**
 * Portfolio
 */

.portfolio_container {
    margin-top: 2em;
}
.portfolio_container_title {
    text-align: center;
    margin-bottom: 0.8em;
}

.portfolio_container_inner {
  min-height: 300px;
}

.portfolio {
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.portfolio-content_inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.portfolio_details {
    position: absolute;
    background: rgba(20,20,20, .5);
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    border: 0.5px solid #fff;
}
a.portmore_see_more {
    color: #fff;
    margin-bottom: 2px;
    margin-top: 2px;
    margin-left: 4px;
    margin-right: 4px;
}
#portfolio_load_more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}

@media screen and (min-width: 767px) {
	.portfolio {
	    max-height: 300px;
	    overflow: hidden;
	}
  .portfolio-content_inner {
    max-height: 300px;
  }

}

// Client Feedback 
.cd-testimonials-wrapper {
  position: relative;
  width: 90%;
  max-width: 768px;
  background-color: #39393c;
  margin: 1em auto 2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 20px 40px 70px;
  z-index: 1;
}

.cd-testimonials-all .cd-testimonials-all-wrapper > ul {
  padding-left: 0;
}

// .cd-testimonials-wrapper::after {
//   /* quotation mark */
//   content: '\201C';
//   position: absolute;
//   top: 10px;
//   left: 0;
//   width: 50px;
//   height: 50px;
//   padding-top: 8px;
//   margin-left: -25px;
//   background: rgba(32, 32, 34, 0.9);
//   font-family: "Georgia", serif;
//   color: white;
//   font-size: 70px;
//   font-size: 4.375rem;
//   text-align: center;
//   /* visible on big devices only */
//   display: none;
// }

.cd-testimonials-wrapper::after {
    content: '\201C';
    position: absolute;
    top: 10px;
    left: 0;
    width: 50px;
    height: 50px;
    padding-top: 35px;
    margin-left: -25px;
    background: rgba(32, 32, 34, 0.9);
    font-family: "Georgia", serif;
    color: white;
    font-size: 70px;
    font-size: 6.375rem;
    text-align: center;
    display: none;
    vertical-align: middle;
    align-items: center;
    display: flex !important;
    justify-content: center;
	  /* visible on big devices only */
	  display: none; 
}


@media only screen and (min-width: 768px) {
  .cd-testimonials-wrapper {
    margin: 1em auto 4em;
    padding: 64px 50px 114px;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-testimonials-wrapper::after {
    /* quotation mark visible */
    display: block;
  }
}

.cd-testimonials {
  color: white;
  text-align: center;
  padding-left: 0;
  list-style: none;
}
.cd-testimonials:after {
  content: "";
  display: table;
  clear: both;
}
.cd-testimonials > li {
  position: absolute;
  opacity: 0;
}
.cd-testimonials > li:first-child {
  position: relative;
  opacity: 1;
}
.cd-testimonials p {
  font-family: "Georgia", serif;
  font-style: italic;
  line-height: 1.4;
  margin-bottom: 1em;
  padding: 0 14px;
  font-size: 2.5rem !important;
}
@media only screen and (min-width: 768px) {
  .cd-testimonials p {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-testimonials p {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.6;
  }
}

.portfolio_filter {
    display: flex;
    justify-content: center;
}


.portfolio_see_more_contents {
    background: rgba(20, 20, 20, 0.5);
    position: fixed;
    top: 50px; //height of the nav bar
    z-index: 999;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    opacity: 1;
    overflow-y: auto;
}

.portfolio_see_more_secondary_inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.portfolio_see_more_contents-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    margin-bottom: 5em;
    background: #F3F3F3;
    padding: 3em 1em;
    position: absolute;
    max-width: 98%;
    // margin-left: 1em;
    // margin-right: 1em;
}

.portfolio_description {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 5%;
    padding-right: 5%;

}

.hide_portfolio_see_more_contents {
    position: absolute;
    font-size: 1.8em;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.cd-author img, .cd-author .cd-author-info {
  display: inline-block;
  vertical-align: middle;
}
.cd-author img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
.cd-author .cd-author-info {
  text-align: left;
  line-height: 1.2;
}
.cd-author .cd-author-info li:first-child {
  font-size: 14px;
  font-size: 0.875rem;
}
.cd-author .cd-author-info li:last-child {
  font-size: 12px;
  font-size: 0.75rem;
  color: #6b6b70;
}
@media only screen and (min-width: 768px) {
  .cd-author img {
    width: 50px;
    height: 50px;
  }
  .cd-author .cd-author-info {
    line-height: 1.4;
  }
  .cd-author .cd-author-info li:first-child {
    font-size: 16px;
    font-size: 1rem;
  }
  .cd-author .cd-author-info li:last-child {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.flex-direction-nav li {
  position: absolute;
  height: 100%;
  width: 40px;
  top: 0;
}
.flex-direction-nav li:first-child {
  left: 0;
}
.flex-direction-nav li:last-child {
  right: 0;
}
.flex-direction-nav li a {
  display: block;
  height: 100%;
  width: 100%;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.no-touch .flex-direction-nav li a:hover {
  background-color: #323234;
}
.flex-direction-nav li a::before, .flex-direction-nav li a::after {
  /* left and right arrows in css only */
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2px;
  height: 13px;
  background-color: white;
}
.flex-direction-nav li a::before {
  -webkit-transform: translateY(-35px) rotate(45deg);
  -moz-transform: translateY(-35px) rotate(45deg);
  -ms-transform: translateY(-35px) rotate(45deg);
  -o-transform: translateY(-35px) rotate(45deg);
  transform: translateY(-35px) rotate(45deg);
}
.flex-direction-nav li a::after {
  -webkit-transform: translateY(-27px) rotate(-45deg);
  -moz-transform: translateY(-27px) rotate(-45deg);
  -ms-transform: translateY(-27px) rotate(-45deg);
  -o-transform: translateY(-27px) rotate(-45deg);
  transform: translateY(-27px) rotate(-45deg);
}
.flex-direction-nav li:last-child a::before {
  -webkit-transform: translateY(-35px) rotate(-45deg);
  -moz-transform: translateY(-35px) rotate(-45deg);
  -ms-transform: translateY(-35px) rotate(-45deg);
  -o-transform: translateY(-35px) rotate(-45deg);
  transform: translateY(-35px) rotate(-45deg);
}
.flex-direction-nav li:last-child a::after {
  -webkit-transform: translateY(-27px) rotate(45deg);
  -moz-transform: translateY(-27px) rotate(45deg);
  -ms-transform: translateY(-27px) rotate(45deg);
  -o-transform: translateY(-27px) rotate(45deg);
  transform: translateY(-27px) rotate(45deg);
}
@media only screen and (min-width: 768px) {
  .flex-direction-nav li {
    width: 50px;
  }
}
@media only screen and (min-width: 1170px) {
  .flex-direction-nav li a::before, .flex-direction-nav li a::after {
    background-color: #5e5e63;
    -webkit-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    transition: background-color 0.2s;
  }
  .flex-direction-nav li a:hover::before, .flex-direction-nav li a:hover::after {
    background-color: white;
  }
}

.cd-see-all {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #252527;
  text-align: center;
  text-transform: uppercase;
  color: #6b6b70;
  font-weight: 700;
  font-size: 12px;
  font-size: 2rem;
  -webkit-transition: color, 0.2s;
  -moz-transition: color, 0.2s;
  transition: color, 0.2s;
}
.no-touch .cd-see-all:hover {
  color: #52B7A6;
}

.cd-testimonials-all {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;
}
.cd-testimonials-all .cd-testimonials-all-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.cd-testimonials-all .cd-testimonials-all-wrapper > ul {
  width: 90%;
  max-width: 1170px;
  margin: 4em auto;
}
.cd-testimonials-all .cd-testimonials-all-wrapper > ul:after {
  content: "";
  display: table;
  clear: both;
}
.cd-testimonials-all .cd-testimonials-item {
  margin-bottom: 2em;
  opacity: 0;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translate3d(0, 0, 0) scale(0.4);
  -moz-transform: translate3d(0, 0, 0) scale(0.4);
  -ms-transform: translate3d(0, 0, 0) scale(0.4);
  -o-transform: translate3d(0, 0, 0) scale(0.4);
  transform: translate3d(0, 0, 0) scale(0.4);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform .3s, opacity .3s;
  -moz-transition: -moz-transform .3s, opacity .3s;
  transition: transform .3s, opacity .3s;
}
.cd-testimonials-all p {
  position: relative;
  background: #52B7A6;
  color: white;
  padding: 2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  border-radius: 0.25em;
  margin-bottom: 1em;
}
.cd-testimonials-all p::after {
  /* creating the triangle in css */
  content: '';
  position: absolute;
  top: 100%;
  left: 2em;
  height: 0;
  width: 0;
  border: 8px solid transparent;
  border-top-color: #52B7A6;
}
.cd-testimonials-all .cd-author {
  text-align: left;
  margin-left: 1.5em;
}
.cd-testimonials-all .cd-author img {
  box-shadow: 0 0 0 transparent;
}
.cd-testimonials-all.is-visible {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s;
}
.cd-testimonials-all.is-visible .cd-testimonials-all-wrapper {
  -webkit-overflow-scrolling: touch;
}
.cd-testimonials-all.is-visible .cd-testimonials-item {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  -moz-transform: translate3d(0, 0, 0) scale(1);
  -ms-transform: translate3d(0, 0, 0) scale(1);
  -o-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}
@media only screen and (min-width: 768px) {
  .cd-testimonials-all .cd-testimonials-item {
    width: 46%;
    margin: 0 2% 3em;
  }
  .cd-testimonials-all .cd-author {
    margin-left: 1em;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-testimonials-all .cd-testimonials-item {
    width: 30%;
    margin: 0 1.6666% 3em;
  }
}

.close-btn {
  position: fixed;
  display: block;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 5%;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}
.close-btn::before, .close-btn::after {
  /* close icon in css */
  content: '';
  position: absolute;
  display: inline-block;
  width: 2px;
  height: 24px;
  top: 8px;
  left: 19px;
  background-color: #39393c;
}
.close-btn::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.close-btn::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
ul.cd-author-info {
    list-style: none;
    padding-left: 0;
}
ul.cd-author-info li {
    font-size: 1.8rem !important;
}
.cd-testimonials-all .cd-testimonials-all-wrapper > ul {
    list-style: none;
    font-size: 2rem;
}
.cd-see-all:hover {
    color: #52B7A6;
    text-decoration: none;
}
.portfolio_details:hover {
	background: rgba(20, 20, 20, 0.2);
}

#mobile-header {
    position: fixed;
    top: 5px;
    left: 5px;
    z-index: 999999999999;
    border: 1px solid #3097D1;
    border-radius: 2px;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}

a#responsive-menu-button {
    margin: 0;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 2em;
    padding: 3px;
    color: #3097D1;
}

.sidr-open #mobile-header {
  left: 265px;
}

//Mobile menu
#mobile-header {
    display: none;
}
@media only screen and (max-width: 767px){
    #mobile-header {
        display: block;
    }
}


.section.first_section {
    background-size: cover;
    color: #fff;
    background-position: 50% 50%;
}
.section-inner {
    padding-top: 1em;
    padding-bottom: 1em;
}
.author-promo .title > h3, .author-promo > h2 {
    font-size: 4em;
}
.author-description {
    font-size: 2em;
    text-align: center;
    margin-top: 1em;
}
.section.first_section > .section-inner {
    background: rgba(20,20,20, .85);
}
.section_main_content {
    padding-top: 5%;
    padding-bottom: 3%;
}

.social_icons ul {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    padding-left: 0;
}
.social_icon_container {
    margin-top: 1em;
}

.social_icons ul li img {
  max-width: 56px;
}

@media screen and (max-width: 440px) {
  .social_icons ul li img {
      max-width: 36px;
  }
}

.social_icons ul li {
    margin: 4px;
}

.typed-cursor{
    opacity: 1;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
}
@keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}


.under_development h1 {
    color: #780007;
}

.se-pre-con {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(../images/preloader.svg) center no-repeat RGBA(243, 243, 243, 0.5);
}

@media screen and (max-width: 480px) {

  .author-promo .title > h3, .author-promo > h2 {
    font-size: 2em;
  }
  .author-description {
      font-size: 1.5em;
  }

}

.single-portfolio {
    border-bottom: 1px solid #fff;
    padding-bottom: 1em;
}

.manage {
    display: flex;
}

.error-page > .error-content {
    margin-left: 0 !important;
}

.error-page {
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.show, .edit, .delete {
    margin: 0 3px;
}